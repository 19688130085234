'use client';

import { useConfig } from '@/app/contexts/ConfigContext';

interface NoteProps {
  number: string;
  title: string;
}

export default function Note({ number, title }: NoteProps) {
  const { config } = useConfig();

  return (
    <div
      className='flex items-center sm:px-1.5 px-1 py-1.5 bg-zinc-100 sm:h-auto'
      style={{ backgroundColor: config.theme.backgroundColorSecondary }}
    >
      <div
        className='p-2 text-center sm:min-w-12 min-w-9 bg-neutral-800 text-white sm:text-xl font-semibold sm:leading-snug text-sm leading-none flex-shrink-0'
        style={{
          color: config.theme.textColorLight,
          backgroundColor: config.theme.textColorDark,
        }}
      >
        {number}
      </div>
      <div
        className='text-neutral-800 ms-1 sm:text-[22px] sm:leading-7 font-medium text-[13px] leading-tight break-words'
        style={{ color: config.theme.textColorDark }}
      >
        {title}
      </div>
    </div>
  );
}
