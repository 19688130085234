'use client';

import { useEffect, useState } from 'react';

import Hero from '@/app/(with-metadata)/(main)/Hero';
import Resources from '@/app/(with-metadata)/(main)/ResourcesSection';
import NotesAndGoogle from '@/components/NotesAndGoogle';
import RecipesSection from '@/app/(with-metadata)/(main)/RecipesSection';

const fetchFrontPageArticles = async (
  origin: string,
  selectedLanguage: LanguageOptions
) =>
  (await import('@/services/articles')).getFrontPageArticles(
    origin,
    selectedLanguage
  );

const fetchFrontPageVideos = async (
  origin: string,
  selectedLanguage: LanguageOptions
) =>
  (await import('@/services/videosContent')).getFrontPageVideos(
    origin,
    selectedLanguage
  );

import BlogSection from '@/app/(with-metadata)/(main)/BlogSection';
import VideosContentSection from '@/app/(with-metadata)/(main)/VideosContentSection';
import { CardDefinition, LanguageOptions } from '@/types';
import { useConfig } from '../../contexts/ConfigContext';

export default function Home() {
  const { selectedLanguage } = useConfig();

  const [videos, setVideos] = useState<CardDefinition[]>([]);
  const [posts, setPosts] = useState<CardDefinition[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const origin = window.location.origin;

      try {
        const loadedVideos = await fetchFrontPageVideos(
          origin,
          selectedLanguage
        );
        const loadedPosts = await fetchFrontPageArticles(
          origin,
          selectedLanguage
        );

        if (loadedVideos && loadedPosts) {
          setVideos(loadedVideos);
          setPosts(loadedPosts);
        } else {
          console.error('No data received');
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [selectedLanguage]);

  return (
    <main className='max-w-[1920px] mx-auto overflow-hidden'>
      <Hero />
      <section className='sm:max-w-[1400px] max-w-[390px] mx-auto px-4'>
        <Resources />
        <NotesAndGoogle />
        <VideosContentSection items={videos} />
        <BlogSection items={posts} />
        <RecipesSection />
      </section>
    </main>
  );
}
