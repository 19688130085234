'use client';

import { useConfig } from '@/app/contexts/ConfigContext';

export default function ReviewGoogle() {
  const { wording, config } = useConfig();

  return (
    <div className='flex flex-row items-center'>
      <img className='h-12 sm:h-[68px]' src='/google.svg' />

      <div>
        <span
          className='text-neutral-800 sm:text-[22px] font-medium sm:leading-7 text-[13px] leading-none'
          style={{ color: config.theme.textColorDark }}
        >
          {wording?.components_review_google_text}
        </span>
        <img
          className='sm:h-[26.75px] h-[19px]'
          src='/stars.svg'
          alt='rating stars'
        />
      </div>
    </div>
  );
}
