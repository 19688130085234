'use client';

import Note from '@/components/Note';
import ReviewGoogle from '@/components/ReviewGoogle';
import { useConfig } from '@/app/contexts/ConfigContext';

export default function NotesAndGoogle() {
  const { wording, config } = useConfig();

  return (
    <>
      <hr
        className='hidden sm:block border border-neutral-300'
        style={{ borderColor: config.theme.borderColor }}
      />
      <section className='grid grid-cols-2 sm:grid-cols-4 sm:gap-x-24 gap-1.5 my-10'>
        <Note
          number='01'
          title={wording!.components_notes_and_google_title_1}
        />
        <Note
          number='02'
          title={wording!.components_notes_and_google_title_2}
        />
        <Note
          number='03'
          title={wording!.components_notes_and_google_title_3}
        />
        <ReviewGoogle />
      </section>
      <hr
        className='hidden sm:block border border-neutral-300 mb-24'
        style={{ borderColor: config.theme.borderColor }}
      />
    </>
  );
}
