'use client';

import { HTMLAttributes } from 'react';
import { useConfig } from '@/app/contexts/ConfigContext';

interface SubheaderProps extends HTMLAttributes<HTMLHeadingElement> {}

export default function Subheader({
  children,
  className,
  ...rest
}: SubheaderProps) {
  const { config } = useConfig();

  return (
    <h2
      className={`text-neutral-800 font-bold sm:text-[45px] sm:leading-[49.50px] text-[28px] leading-[30.80px] ${className}`}
      style={{ color: config.theme.textColorDark }}
      {...rest}
    >
      {children}
    </h2>
  );
}
