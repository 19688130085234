'use client';

import Button from '@/components/Button';
import SectionTitle from '@/components/SectionTitle';
import Subheader from '@/components/Subheader';
import Cards from '@/components/Cards';
import { useConfig } from '@/app/contexts/ConfigContext';

import { CardDefinition } from '@/types';
import CustomLink from '@/components/CustomLink';

interface BlogSectionProps {
  items: CardDefinition[];
}

export default function BlogSection({ items }: BlogSectionProps) {
  const { wording, config } = useConfig();

  return (
    <section className='sm:mt-40 mt-8'>
      <SectionTitle>{wording?.blog_section_title}</SectionTitle>
      <div className='flex items-end justify-between items-center mt-4'>
        <Subheader>{wording?.blog_section_subheader_text}</Subheader>
        <Button
          variant='secondary'
          className='!px-5 sm:!w-[295px]'
          themeBackgroundColor={config.theme.textColorDark}
          themeTextColor={config.theme.textColorLight}
        >
          <CustomLink href='/articles'>
            {wording?.blog_section_button_more}
          </CustomLink>
        </Button>
      </div>
      <Cards items={items} />
    </section>
  );
}
