'use client';

import Button from '@/components/Button';
import CustomLink from '@/components/CustomLink';
import DipHorizontalGradient from '@/components/DipHorizontalGradient';
import DipVerticalGradient from '@/components/DipVerticalGradient';
import SectionTitle from '@/components/SectionTitle';
import Subheader from '@/components/Subheader';
import Text from '@/components/Text';
import { useConfig } from '@/app/contexts/ConfigContext';
import { SERVICE_TYPES } from '@/helpers/enums';

export default function ResourcesSection() {
  const { wording, config } = useConfig();

  return (
    <section className='sm:grid sm:grid-cols-2 sm:gap-x-20 sm:mt-16 mt-8 sm:mb-32 mb-8'>
      <SectionTitle className='sm:col-span-2'>
        {wording?.components_ressources_section_title}
      </SectionTitle>
      <div className='flex flex-col'>
        <Subheader className='mt-4 sm:mb-8 mb-3'>
          {wording?.components_ressources_subheader_text}
        </Subheader>
        <span
          className='text-neutral-500 sm:text-xl text-sm font-medium sm:leading-[29px] leading-tight'
          style={{ color: config.theme.textColorDarkSecondary }}
        >
          {wording?.components_ressources_span_text}
        </span>
        <div className='sm:mt-28 mt-5 mb-4 grid grid-cols-2 gap-3 sm:block z-10'>
          <div
            className='flex sm:flex-row flex-col sm:gap-10 gap-4 items-center justify-between sm:p-8 py-4 px-3 bg-zinc-100'
            style={{ backgroundColor: config.theme.backgroundColorSecondary }}
          >
            <div className='sm:ml-[15px]'>
              <h3
                className='mb-3 text-neutral-800 sm:text-3xl font-bold sm:leading-[33px] text-xl leading-snug'
                style={{ color: config.theme.textColorDark }}
              >
                {wording?.components_ressources_card_1_title}
              </h3>
              <Text>{wording?.components_ressources_card_1_text}</Text>
            </div>
            <img
              src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/motivation.webp`}
              alt='a motivation image'
            />
          </div>
          <div
            className='sm:mt-5 flex sm:flex-row flex-col sm:gap-10 gap-4 items-center justify-between sm:p-8 py-4 px-3 bg-zinc-100'
            style={{ backgroundColor: config.theme.backgroundColorSecondary }}
          >
            <div>
              <h3
                className='mb-3 text-neutral-800 sm:text-3xl font-bold sm:leading-[33px] text-xl leading-snug'
                style={{ color: config.theme.textColorDark }}
              >
                {wording?.components_ressources_card_2_title}
              </h3>
              <Text>{wording?.components_ressources_card_2_text}</Text>
            </div>
            <img
              src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/diet.webp`}
              alt='a diet image'
            />
          </div>
        </div>
      </div>
      <div className='relative'>
        <div
          className={`absolute sm:w-[645px] sm:h-[645px] w-[270px] h-[270px] bottom-0 top-0 -right-12 sm:right-0 sm:m-0 m-auto bg-gradient-to-b from-fuchsia-300 to-indigo-200 rounded-full z-[-10]`}
          style={
            config.theme.gradientColorStart && config.theme.gradientColorEnd
              ? {
                  backgroundImage: `linear-gradient(to bottom, ${config.theme.gradientColorStart}, ${config.theme.gradientColorEnd})`,
                }
              : {}
          }
        />
        <div className='flex items-center justify-center h-full'>
          <div className='relative'>
            <img
              className='hidden sm:block relative'
              src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/dip-vertical.webp`}
              alt='an image'
            />
            <DipVerticalGradient
              className='hidden sm:block absolute top-0 left-0 opacity-50'
              colorStart={config.theme.gradientColorStart}
              colorEnd={config.theme.gradientColorEnd}
            />
            <img
              className='block sm:hidden relative'
              src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/dip-horizontal.webp`}
              alt='an image'
            />
            <DipHorizontalGradient
              className='block sm:hidden absolute top-0 left-0 opacity-50 w-full h-auto max-w-[390px] max-h-[189px]'
              colorStart={config.theme.gradientColorStart}
              colorEnd={config.theme.gradientColorEnd}
            />
            <Button
              className='absolute left-4 bottom-5'
              themeBackgroundColor={config.theme.accentColorOne}
              themeTextColor={config.theme.textColorDark}
            >
              <CustomLink
                href={
                  config.serviceType === SERVICE_TYPES.FITNESS
                    ? '/videosContent/men'
                    : '/videosContent/all'
                }
              >
                {wording?.components_ressources_link}
              </CustomLink>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
