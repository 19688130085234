'use client';

import { useState } from 'react';
import Button from '@/components/Button';
import SectionTitle from '@/components/SectionTitle';
import Subheader from '@/components/Subheader';
import Cards from '@/components/Cards';
import { useConfig } from '@/app/contexts/ConfigContext';
import { FITNESS_WORDING_EN } from '@/wording/fitness/FITNESS_WORDING_EN';

import { CardDefinition } from '@/types';
import Filters from '@/components/Filters';
import CustomLink from '@/components/CustomLink';
import { SERVICE_TYPES } from '@/helpers/enums';

interface VideosContentSectionProps {
  items: CardDefinition[];
}

export default function VideosContentSection({
  items,
}: VideosContentSectionProps) {
  const { wording, config } = useConfig();

  const [selectedState, setSelectedState] = useState<string>(
    FITNESS_WORDING_EN.text_men
  );

  const videosContentLinkUrl = () => {
    if (config.serviceType !== SERVICE_TYPES.FITNESS) {
      return '/videosContent/all';
    } else {
      return selectedState === FITNESS_WORDING_EN.text_men
        ? '/videosContent/men'
        : '/videosContent/women';
    }
  };

  return (
    <section>
      <SectionTitle>{wording?.videos_content_section_title}</SectionTitle>
      {config.serviceType === SERVICE_TYPES.FITNESS && (
        // Conditional display to have the same css across the app
        <Subheader className='mt-4'>
          {wording?.videos_content_section_subheader_text}
        </Subheader>
      )}
      <div className='flex items-end justify-between items-center mt-4'>
        {config.serviceType !== SERVICE_TYPES.FITNESS && (
          // Conditional display to have the same css across the app
          <Subheader className='mt-4'>
            {wording?.videos_content_section_subheader_text}
          </Subheader>
        )}
        <div className='flex sm:gap-12 gap-6'>
          {config.serviceType === SERVICE_TYPES.FITNESS && wording && (
            // Only for fitness service
            <Filters
              options={[
                { name: wording.text_men, value: FITNESS_WORDING_EN.text_men },
                {
                  name: wording.text_women,
                  value: FITNESS_WORDING_EN.text_women,
                },
              ]}
              activeOption={selectedState}
              onSelect={(value) => setSelectedState(value)}
            />
          )}
        </div>
        <Button
          variant='secondary'
          className='!px-1 py-2 w-[125px] sm:!w-[295px]'
          themeBackgroundColor={config.theme.textColorDark}
          themeTextColor={config.theme.textColorLight}
        >
          <CustomLink href={videosContentLinkUrl()}>
            {wording?.videos_content_section_button_more}
          </CustomLink>
        </Button>
      </div>
      <Cards
        items={
          config.serviceType === SERVICE_TYPES.FITNESS
            ? // If we are in a fitness service we need to filter the show either men or women
              items.filter((item) => item.tags.includes(selectedState))
            : // Else we show everything
              items
        }
      />
    </section>
  );
}
