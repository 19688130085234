'use client';

import Button from '@/components/Button';
import SectionTitle from '@/components/SectionTitle';
import Subheader from '@/components/Subheader';
import Text from '@/components/Text';
import { useConfig } from '@/app/contexts/ConfigContext';
import CustomLink from '@/components/CustomLink';
import { isStringLongerThanMaxLength } from '@/helpers';

export default function RecipesSection() {
  const { wording, config } = useConfig();

  const titleLanguageSpecificStyle = () => {
    if (
      isStringLongerThanMaxLength(
        wording?.components_recipes_card_3_text as string,
        173
      )
    ) {
      return {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        marginBottom: '0px',
      };
    }
  };

  const textLanguageSpecificStyle = () => {
    if (
      isStringLongerThanMaxLength(
        wording?.components_recipes_card_3_text as string,
        173
      )
    ) {
      return {
        fontSize: '0.75rem',
        lineHeight: '1rem',
      };
    }
  };

  return (
    <section className='sm:mt-28 sm:grid sm:grid-cols-2 sm:gap-x-16 sm:mt-16 mt-8 sm:mb-32 mb-8'>
      <div className='flex flex-col'>
        <SectionTitle>{wording?.components_recipes_section_title}</SectionTitle>
        <Subheader className='mt-4 sm:mb-8 mb-3'>
          {wording?.components_recipes_subheader_text}
        </Subheader>
        <span
          className='text-neutral-500 sm:text-xl text-sm font-medium sm:leading-[29px] leading-tight'
          style={{ color: config.theme.textColorDarkSecondary }}
        >
          {wording?.components_recipes_span_text}
        </span>
        <div className='sm:mt-16 mt-5 mb-4 grid grid-cols-2 gap-3 sm:block z-10'>
          <div
            className='sm:p-16 py-4 px-3 bg-zinc-100'
            style={{ backgroundColor: config.theme.backgroundColorSecondary }}
          >
            <h3
              className='mb-3 text-neutral-800 sm:text-3xl font-bold sm:leading-[33px] text-xl leading-snug'
              style={{ color: config.theme.textColorDark }}
            >
              {wording?.components_recipes_card_1_title}
            </h3>
            <Text>{wording?.components_recipes_card_1_text}</Text>
            <div className='sm:mt-8 mt-3'>
              <img
                className='hidden sm:block relative'
                src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/breakfast.webp`}
                alt='a breakfast image'
              />
              <img
                className='block sm:hidden relative'
                src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/breakfast-mobile.webp`}
                alt='a breakfast image'
              />
            </div>
          </div>
          <div
            className='sm:hidden py-4 px-3 bg-primary'
            style={{ backgroundColor: config.theme.accentColorOne }}
          >
            <div>
              <h3
                className='mb-3 text-neutral-800 sm:text-3xl font-bold sm:leading-[33px] text-xl leading-snug'
                style={{ color: config.theme.textColorDark }}
              >
                {wording?.components_recipes_card_2_title}
              </h3>
              <Text className='text-neutral-800'>
                {wording?.components_recipes_card_2_text}
              </Text>
            </div>
          </div>
        </div>
      </div>
      {/* Use in desktop to change the position of the "cards" */}
      <div className='sm:flex flex-col hidden'>
        <div className='relative'>
          <img
            src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/lunch.webp`}
            alt='a lunch image'
          />
          <div className='absolute bottom-0 z-10 p-16'>
            <h3
              className='mb-3 text-white sm:text-3xl font-bold sm:leading-[33px] text-xl leading-snug'
              style={{ color: config.theme.textColorLight }}
            >
              {wording?.components_recipes_card_3_title}
            </h3>
            <Text
              className='text-white'
              style={{ color: config.theme.textColorLight }}
            >
              {wording?.components_recipes_card_3_text}
            </Text>
          </div>
        </div>
        <div
          className='mt-12 p-16 bg-primary'
          style={{ backgroundColor: config.theme.accentColorOne }}
        >
          <div>
            <h3
              className='mb-3 text-neutral-800 sm:text-3xl font-bold sm:leading-[33px] text-xl leading-snug'
              style={{ color: config.theme.textColorDark }}
            >
              {wording?.components_recipes_card_2_title}
            </h3>
            <Text className='text-neutral-800'>
              {wording?.components_recipes_card_2_text}
            </Text>
          </div>
        </div>
      </div>
      <div className='sm:hidden sm:p-8 p-2 relative'>
        <div className='relative z-10'>
          <h3
            className='mb-3 text-white sm:text-3xl font-bold sm:leading-[33px] text-xl leading-snug'
            style={{
              color: config.theme.textColorLight,
              ...titleLanguageSpecificStyle(),
            }}
          >
            {wording?.components_recipes_card_3_title}
          </h3>
          <Text
            className='text-white'
            style={{
              color: config.theme.textColorLight,
              ...textLanguageSpecificStyle(),
            }}
          >
            {wording?.components_recipes_card_3_text}
          </Text>
        </div>
        <img
          className='absolute top-0 left-0'
          src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/lunch-mobile.webp`}
          alt='a lunch image'
        />
      </div>
      <div className='mt-8 col-span-2 text-center'>
        <Button themeBackgroundColor={config.theme.accentColorOne}>
          <CustomLink
            href='/recipes'
            style={{ color: config.theme.textColorDark }}
          >
            {wording?.components_recipes_link}
          </CustomLink>
        </Button>
      </div>
    </section>
  );
}
