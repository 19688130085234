'use client';

import Image from 'next/image';
import dayjs from 'dayjs';
import { Lato } from 'next/font/google';
import Tag from '@/components/Tag';
import { useConfig } from '@/app/contexts/ConfigContext';
import CustomLink from './CustomLink';

const lato = Lato({ subsets: ['latin'], weight: '700' });

interface CardProps {
  timestamp: number;
  tags: string[];
  name: string;
  imageSrc: string;
  type: 'video' | 'recipe' | 'article';
  link?: string;
}

export default function Card({
  tags,
  name,
  timestamp,
  imageSrc,
  type,
  link = '',
}: CardProps) {
  const { wording, config } = useConfig();

  return (
    <div
      className='sm:p-[38px] p-4 bg-white border border-neutral-300'
      style={{
        backgroundColor: config.theme.backgroundColor,
        borderColor: config.theme.borderColor,
      }}
    >
      <div className='relative'>
        <CustomLink href={link}>
          {type === 'video' && (
            <svg
              width='70'
              height='70'
              viewBox='0 0 70 70'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='absolute left-0 right-0 top-0 bottom-0 m-auto sm:h-[70px] h-[58px]'
            >
              <g id='Play Button'>
                <circle
                  id='Ellipse'
                  cx='35'
                  cy='35'
                  r='35'
                  fill={config.theme.playButtonCircle || '#7572F7'}
                />
                <path
                  id='Vector'
                  d='M30.7783 44.9415L42.218 34.8637L30.7783 24.7859V44.9415Z'
                  fill={config.theme.playButtonTriangle || 'white'}
                />
              </g>
            </svg>
          )}
          <Image
            width={388}
            height={218}
            className='w-auto'
            alt=''
            src={imageSrc}
          />
        </CustomLink>
      </div>
      <div className='mt-5 flex flex-row flex-wrap gap-3'>
        <div
          className={`${lato.className} text-secondary text-xl font-medium capitalize`}
          style={{ color: config.theme.accentColorTwo }}
        >
          {dayjs(timestamp).format('MMM DD, YYYY')}
        </div>
        {tags.map((tag) => (
          <Tag key={`${timestamp}-${tag}`} name={tag} />
        ))}
      </div>
      <div
        className='mt-5 text-neutral-800 sm:text-2xl text-xl font-bold'
        style={{ color: config.theme.textColorDark }}
      >
        {name}
      </div>
      <CustomLink href={link}>
        {type === 'video' && (
          <div
            className={`${lato.className} mt-5 text-secondary text-xl font-medium`}
            style={{ color: config.theme.accentColorTwo }}
          >
            {wording?.components_card_link_video_content}
          </div>
        )}

        {type === 'recipe' && (
          <div
            className={`${lato.className} mt-5 text-secondary text-xl font-medium`}
            style={{ color: config.theme.accentColorTwo }}
          >
            {wording?.components_card_link_recipe}
          </div>
        )}
        {type === 'article' && (
          <div
            className={`${lato.className} mt-5 text-secondary text-xl font-medium`}
            style={{ color: config.theme.accentColorTwo }}
          >
            {wording?.components_card_link_article}
          </div>
        )}
        {/* {type === 'article' && (
        <div className='flex flex-row items-center gap-3 mt-5'>
          <img className='h-[55px] rounded-full' src='/author.webp' />
          <span
            className={`${lato.className} text-neutral-800 text-xl font-medium capitalize`}
          >
            William Wilkins
          </span>
        </div>
      )} */}
      </CustomLink>
    </div>
  );
}
