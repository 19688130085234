'use client';

import { useConfig } from '@/app/contexts/ConfigContext';
import { FITNESS_WORDING_EN } from '@/wording/fitness/FITNESS_WORDING_EN';

interface TagsProps {
  name: string;
}

export default function Tag({ name }: TagsProps) {
  const { wording, config } = useConfig();

  let nameTranslated: string;

  // Tags/Labels use English wording as a base for consistency across languages.
  // They are translated at runtime using the selected language’s wording file.
  switch (name) {
    case FITNESS_WORDING_EN.text_men:
      nameTranslated = wording!.text_men;
      break;
    case FITNESS_WORDING_EN.text_women:
      nameTranslated = wording!.text_women;
      break;
    case FITNESS_WORDING_EN.page_videos_content_menu_biceps:
      nameTranslated = wording!.page_videos_content_menu_biceps!;
      break;
    case FITNESS_WORDING_EN.page_videos_content_menu_triceps:
      nameTranslated = wording!.page_videos_content_menu_triceps!;
      break;
    case FITNESS_WORDING_EN.page_videos_content_menu_back:
      nameTranslated = wording!.page_videos_content_menu_back!;
      break;
    case FITNESS_WORDING_EN.page_videos_content_menu_chest:
      nameTranslated = wording!.page_videos_content_menu_chest!;
      break;
    case FITNESS_WORDING_EN.page_videos_content_menu_legs:
      nameTranslated = wording!.page_videos_content_menu_legs!;
      break;
    case FITNESS_WORDING_EN.page_videos_content_menu_shoulders:
      nameTranslated = wording!.page_videos_content_menu_shoulders!;
      break;
    case FITNESS_WORDING_EN.page_videos_content_menu_abdominals:
      nameTranslated = wording!.page_videos_content_menu_abdominals!;
      break;
    default:
      nameTranslated = name;
      break;
  }

  return (
    <div
      className='min-w-20 bg-zinc-100 text-neutral-800 text-xl font-medium capitalize px-2 text-center'
      style={{
        color: config.theme.textColorDark,
        backgroundColor: config.theme.backgroundColorSecondary,
      }}
    >
      {nameTranslated}
    </div>
  );
}
