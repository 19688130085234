'use client';

import { HTMLAttributes } from 'react';
import { useConfig } from '@/app/contexts/ConfigContext';

interface TextProps extends HTMLAttributes<HTMLSpanElement> {}

export default function Text({ children, className, ...rest }: TextProps) {
  const { config } = useConfig();

  return (
    <span
      className={`text-neutral-500 sm:text-xl text-sm font-medium sm:leading-[29px] leading-tight ${className}`}
      style={{ color: config.theme.textColorDark }}
      {...rest}
    >
      {children}
    </span>
  );
}
