'use client';

import { HTMLAttributes } from 'react';
import { useConfig } from '@/app/contexts/ConfigContext';

interface SectionTitleProps extends HTMLAttributes<HTMLSpanElement> {}

export default function SectionTitle({
  children,
  className,
  ...rest
}: SectionTitleProps) {
  const { config } = useConfig();

  return (
    <span
      className={`text-secondary sm:text-xl text-[15px] font-semibold uppercase sm:leading-snug leading-none sm:tracking-[8px] tracking-[6px] ${className}`}
      style={{ color: config.theme.accentColorTwo }}
      {...rest}
    >
      {children}
    </span>
  );
}
