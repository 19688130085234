import { Lato } from 'next/font/google';
import { HTMLAttributes } from 'react';

const lato = Lato({ subsets: ['latin'], weight: '700' });

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
  themeTextColor?: string;
  themeBackgroundColor?: string;
}

export default function Button({
  variant = 'primary',
  children,
  className,
  themeTextColor = '',
  themeBackgroundColor = '',
  ...rest
}: ButtonProps) {
  return (
    <button
      className={`${lato.className} ${
        variant === 'primary'
          ? 'text-zinc-800 bg-primary'
          : 'text-white bg-neutral-800'
      } font-semibold text-[16.89px] sm:text-[22px] sm:py-4 py-3 sm:px-12 px-9 ${className}`}
      {...rest}
      style={{
        color: themeTextColor,
        backgroundColor: themeBackgroundColor,
      }}
    >
      {children}
    </button>
  );
}
