import FilterValue from './FilterValue';

interface FiltersProps<T> {
  options: { name: string; value: T }[];
  activeOption: T;
  onSelect: (value: T) => void;
}

export default function Filters<T>({
  options,
  activeOption,
  onSelect,
}: FiltersProps<T>) {
  return options.map(({ name, value }) => (
    <FilterValue
      key={`filter-${value}`}
      active={value === activeOption}
      name={name}
      onClick={() => onSelect(value)}
    />
  ));
}
