'use client';

import Image from 'next/image';
import Button from '@/components/Button';
import { useConfig } from '@/app/contexts/ConfigContext';
import CustomLink from '@/components/CustomLink';
import { SERVICE_TYPES } from '@/helpers/enums';
import { useEffect, useState } from 'react';

export default function Hero() {
  const { wording, config, selectedLanguage } = useConfig();

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Use to execute client side only code so next can recognize window
    setIsClient(true);
  }, []);

  const textLanguageSpecificStyle = () => {
    // Small size screen
    if (isClient && window.innerWidth < 1136) {
      // Fitness languages specific style
      if (config.serviceType === SERVICE_TYPES.FITNESS) {
        if (
          selectedLanguage === 'FR' ||
          selectedLanguage === 'BG' ||
          selectedLanguage === 'PL'
        ) {
          return {
            fontSize: '28px',
          };
        }
      }
      // Yoga languages specific style
      if (config.serviceType === SERVICE_TYPES.YOGA) {
        if (selectedLanguage === 'EE' || selectedLanguage === 'LV') {
          return {
            fontSize: '28px',
          };
        }
      }
      // Pilates languages specific style
      if (config.serviceType === SERVICE_TYPES.PILATES) {
        if (selectedLanguage === 'EE' || selectedLanguage === 'LV') {
          return {
            fontSize: '28px',
          };
        }
      }
    }
  };

  const buttonTextLanguageSpecificStyle = () => {
    // Small size screen
    if (isClient && window.innerWidth < 1136) {
      // Fitness languages specific style
      if (config.serviceType === SERVICE_TYPES.FITNESS) {
        if (selectedLanguage === 'FR') {
          return {
            fontSize: '14px',
          };
        }
      }
    }
  };

  return (
    <div className='relative sm:max-w-[1710px] max-w-[390px] mx-auto'>
      <Image
        className='hidden sm:block relative'
        height={655}
        width={1710}
        src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/hero.webp`}
        alt='hero'
        priority
      />
      <Image
        height={208}
        width={390}
        className='block sm:hidden relative'
        src={`https://s3.eu-west-1.amazonaws.com/infinitgame.stockage/coachingServices/${config.serviceType}/images/hero-mobile.webp`}
        alt='hero'
        priority
      />
      <div className='absolute sm:top-36 top-8 sm:left-36 left-5'>
        <div
          className='text-white sm:text-[80px] text-[32px] font-extrabold sm:leading-[88px] leading-9'
          style={{
            color: config.theme.textColorLight,
            ...textLanguageSpecificStyle(),
          }}
        >
          {wording?.hero_text}
        </div>
        <Button
          className='sm:mt-10 mt-6 !px-4'
          themeTextColor={config.theme.textColorDark}
          themeBackgroundColor={config.theme.accentColorOne}
        >
          <CustomLink
            style={{
              ...buttonTextLanguageSpecificStyle(),
            }}
            href={
              config.serviceType === SERVICE_TYPES.FITNESS
                ? '/videosContent/women'
                : '/videosContent/all'
            }
          >
            {wording?.hero_button_text}
          </CustomLink>
        </Button>
      </div>
    </div>
  );
}
