'use client';

import { HTMLAttributes } from 'react';
import { useConfig } from '@/app/contexts/ConfigContext';

interface FilterValueProps extends HTMLAttributes<HTMLButtonElement> {
  name: string;
  active?: boolean;
}

export default function FilterValue({
  name,
  active,
  ...rest
}: FilterValueProps) {
  const { config } = useConfig();

  return (
    <button
      className={`${
        active
          ? 'text-secondary border-secondary'
          : 'text-neutral-800 border-neutral-800'
      } font-bold uppercase border-b-2 sm:pb-5 sm:text-xl pb-[10px] text-lg whitespace-nowrap text-center`}
      style={{
        color: active
          ? config.theme.accentColorTwo
          : config.theme.textColorDark,
        borderColor: active
          ? config.theme.accentColorTwo
          : config.theme.textColorDark,
      }}
      {...rest}
    >
      {name}
    </button>
  );
}
