import React from 'react';

interface DipHorizontalGradientProps {
  className?: string;
  colorStart?: string;
  colorEnd?: string;
  opacityEnd?: number;
}

const darkenColor = (hex: string, percent: number) => {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = Math.max(0, Math.min(255, r - Math.round((r * percent) / 100)));
  g = Math.max(0, Math.min(255, g - Math.round((g * percent) / 100)));
  b = Math.max(0, Math.min(255, b - Math.round((b * percent) / 100)));

  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
};

const DipHorizontalGradient: React.FC<DipHorizontalGradientProps> = ({
  className = '',
  colorStart,
  colorEnd,
  opacityEnd = 0.72,
}) => {
  // Default values
  const baseColorStart = colorStart || '#524FF5';
  const baseColorEnd = colorEnd || '#060443';

  // Colors darken by 40 %
  const computedColorStart = darkenColor(baseColorStart, 40);
  const computedColorEnd = darkenColor(baseColorEnd, 40);

  return (
    <svg
      className={className}
      width='390'
      height='189'
      viewBox='0 0 390 189'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 0H390V189H0V0Z'
        fill='url(#dip_horizontal_gradient)'
        style={{ mixBlendMode: 'screen' }}
      />
      <defs>
        <linearGradient
          id='dip_horizontal_gradient'
          x1='195'
          y1='0'
          x2='195'
          y2='391.88'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={computedColorStart} />
          <stop
            offset='0.950417'
            stopColor={computedColorEnd}
            stopOpacity={opacityEnd}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DipHorizontalGradient;
