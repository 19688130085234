import { CardDefinition } from '@/types';
import Card from './Card';

interface CardsProps {
  items: CardDefinition[];
}

export default function Cards({ items }: CardsProps) {
  return (
    <div className='mt-8 grid grid-cols-1 sm:grid-cols-3 sm:gap-0 gap-2'>
      {items.map((item) => (
        <Card key={item.id} {...item} />
      ))}
    </div>
  );
}
